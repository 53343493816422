*:where(:not(iframe, canvas, details, img, summary, svg, svg *, symbol *, video)) {
  all: unset;
  display: revert;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
:where([hidden]) {
  display: none;
}
:where(address, article, aside, body, dialog, div, dl, fieldset, figure, footer, form, header, hgroup, html, iframe, label, legend, li, main, menu, nav, ol, section, ul) {
  display: flex;
  flex-direction: column;
}
div {}

a,
button {
  cursor: revert;
}
blockquote,
q {
  quotes: none;
}
blockquote::before,
q::before,
blockquote::after,
q::after {
  content: '';
  content: none;
}
:focus-visible {
  outline: revert;
}
html {
  /* text-size-adjust: none; */
}
meter {
  appearance: revert;
}
:where(ol, ul, menu) {
  list-style: none;
}
::placeholder {
  color: unset;
}
table {
  border-collapse: collapse;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 1.4em;
  font-weight: bold;
  margin: 0.5em 0 0.5em 0;
}

h2 {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.2em 0 0.5em 0;
}

a {
  color: blue;
  
}

h2 a {
  color: black;
}
