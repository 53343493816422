
.App-logo {
  /* height: 5vmin; */
  padding: 2vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  /* display: flex; */
    
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 1vmin); */
  color: white;
  /* width: 100%; */
  padding-bottom: 1em;
}

.App-header div {
  display: block;
}
.App-header div a {
  color: white;
  text-decoration: underline #dddddd ;
}


.App-link {
  color: #61dafb;
}

main {
  padding-left: 5vmin;
  align-items: center;
  /* justify-content: center; */
}

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message {
  padding: 1em;
  font-size: 1.5em;
  font-weight: bold;

}
.loading {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: orange;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}


ul.tourlist {
}

ul.tourlist>li {
  list-style: none;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  padding: 1vmin;
}

ul.tourlist .tourInfo li {
  list-style: disc;
  display: list-item;
}


ul.tourlist .tour img {
  max-width:300px;
  max-height:500px;  
  width: auto;
  height: auto;  
  padding-right: 2vmin;
  /*margin-top: 0.5em;*/
}

ul.tourlist .tour {
  flex-direction: row;
}

ul.tourlist a {
  font-weight: bold;
}

.tourInfo .yousave {
  color: #e04f67;
  font-weight: bold;
}

ul.tourlist .tour .tourInfo h2 {
  margin: 0;
}

.tourImage {
  flex: 1;
}

.tourInfo {
  flex: 1;
}

.tourInfo p {
  padding-bottom: 0.5em;
}

@media only screen and (max-width: 600px) {
  main {
    /* padding-left: 1vmin; */
    /* font-size: 1vmin;  */
  }

  /* ul.tourlist {
    padding: 0;
    margin: 0;
  } */

  ul.tourlist .tour img {
    max-width:100px;
    max-height:100px;  
    width: auto;
    height: auto;  
    /* padding-right: 1vmin; */
  }

  ul.tourlist li {
    margin: 0;
    padding: 0;
  }
}
